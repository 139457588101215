// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// init swiper
new Swiper (".swiper-container", {
    autoplay: document.querySelectorAll(".swiper-container .swiper-slide").length > 1 ? 3000 : 0,
    speed: 150,
    loop: true,
});
