// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const search_block      = document.querySelector(".search-block");
const search_toggle     = document.querySelector(".panel-toggle[data-toggle=search]");
const search_background = document.querySelector(".search_background");
const focus_trapper     = focusTrap(".search-block");

const toggle_search = () => {
    search_block.classList.toggle("is-active");

    if (search_block.classList.contains("is-active")) {
        focus_trapper.activate();
        search_block.setAttribute("aria-hidden", "false");
        search_block.querySelector(".search-form_input").focus();
    } else {
        focus_trapper.deactivate();
        search_block.setAttribute("aria-hidden", "true");
        search_toggle.focus();
    }
};

search_toggle.addEventListener("click", () => {
    toggle_search();
});

search_background.addEventListener("click", () => {
    toggle_search();
});
